"use client"

import { cn } from "@/lib/utils"

interface MarqueeProps {
  className?: string
  children: React.ReactNode
  pauseOnHover?: boolean
}

export function Marquee({ className, children, pauseOnHover = true }: MarqueeProps) {
  return (
    <div className="overflow-hidden">
      <div className={cn(
        "flex min-w-full shrink-0 gap-8 items-center",
        pauseOnHover && "hover:[animation-play-state:paused]",
        className
      )}>
        {/* First copy */}
        <div className="flex min-w-full shrink-0 items-center justify-around gap-8 animate-marquee">
          {children}
        </div>
        {/* Second copy */}
        <div className="flex min-w-full shrink-0 items-center justify-around gap-8 animate-marquee">
          {children}
        </div>
      </div>
    </div>
  )
} 