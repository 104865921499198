export interface Company {
  name: string
  logo: string
  url?: string
}

export const companies: Company[] = [
  {
    name: "Alva",
    logo: "/companies/Alva.png",
    url: "https://alvaauto.com"
  },
  {
    name: "Bola",
    logo: "/companies/Bola.png",
    url: "https://bola.co.id"
  },
  {
    name: "Brankas",
    logo: "/companies/Brankas.png",
    url: "https://brankas.com"
  },
  {
    name: "EngageRocket",
    logo: "/companies/EngageRocket.png",
    url: "https://engagerocket.co"
  },
  {
    name: "Happy5",
    logo: "/companies/Happy5.png",
    url: "https://happy5.co"
  },
  {
    name: "Job2go",
    logo: "/companies/Job2go.png",
    url: "https://job2go.co"
  },
  {
    name: "KlikDokter",
    logo: "/companies/KlikDokter.png",
    url: "https://klikdokter.com"
  },
  {
    name: "Kolase",
    logo: "/companies/Kolase.png",
    url: "https://kolase.com"
  },
  {
    name: "Liputan6",
    logo: "/companies/Liputan6.png",
    url: "https://liputan6.com"
  },
  {
    name: "MakroPro",
    logo: "/companies/MakroPro.png",
    url: "https://makropro.com"
  },
  {
    name: "McKinsey",
    logo: "/companies/McKinsey.png",
    url: "https://McKinsey.com"
  },
  {
    name: "Moka",
    logo: "/companies/Moka.png",
    url: "https://Mokapos.com"
  },
  {
    name: "Oddle",
    logo: "/companies/Oddle.png",
    url: "https://Oddle.me"
  },
  {
    name: "PergiUmroh",
    logo: "/companies/PergiUmroh.png",
    url: "https://pergiumroh.com"
  },
  {
    name: "Peto",
    logo: "/companies/Peto.png",
    url: "https://peto.pet"
  },
  {
    name: "Stemly",
    logo: "/companies/Stemly.png",
    url: "https://stemly.ai"
  },
  {
    name: "Suzuki",
    logo: "/companies/Suzuki.png",
    url: "https://suzuki.co.id"
  },
  {
    name: "Vidio",
    logo: "/companies/Vidio.png",
    url: "https://vidio.com"
  },
  {
    name: "Vrbo",
    logo: "/companies/Vrbo.png",
    url: "https://vrbo.com"
  },
  {
    name: "Workmate",
    logo: "/companies/Workmate.png",
    url: "https://workmate.asia"
  }
] 