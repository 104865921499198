export interface WorkItem {
  title: string
  subtitle: string
  description: string
  image: string
  slug: string
  date: string
  categories: string[]
  platform: string
  industry: string
  businessModel: string
  featured?: boolean
  projectType?: string[]
  companyStage?: string
  location?: string
  link?: string
  coverType: 'video' | 'image'
  coverMedia: string
}

export function getUsedCategories(worksList: WorkItem[]) {
  const categories = new Set(['All']) // Always include "All"
  worksList.forEach(work => {
    work.categories.forEach(category => categories.add(category))
  })
  return Array.from(categories)
}

export const works: WorkItem[] = [
  {
    title: "Stemly",
    subtitle: "Revolutionizing Supply Chain Decision Intelligence",
    description: "A comprehensive platform that leverages AI and ML to transform supply chain and finance decision-making processes.",
    image: "/placeholder.svg?height=400&width=600",
    slug: "stemly",
    date: "2021-10",
    platform: "Web App",
    industry: "Supply Chain",
    businessModel: "B2B",
    categories: ["B2B", "Web App", "Supply Chain"],
    featured: true,
    projectType: ["Product Design", "Product Management"],
    companyStage: "Early Stage",
    location: "Singapore",
    link: "https://stemly.ai",
    coverType: 'video',
    coverMedia: '/works/stemly/cover.mp4',
  },
  {
    title: "EngageRocket",
    subtitle: "Implementing 'The Lattice of Value' to Increase Customer's Adoption",
    description: "Transforming the way companies engage with their employees through timely and relevant information for managers and leaders.",
    image: "/placeholder.svg?height=400&width=600",
    slug: "engagerocket",
    date: "2022-03",
    platform: "Web App",
    industry: "HRTech",
    businessModel: "B2B",
    categories: ["B2B", "Web App", "HRTech"],
    featured: true,
    coverType: 'video',
    coverMedia: '/works/engagerocket/cover.mp4',
  },
  {
    title: "Workmate",
    subtitle: "Refining a Scalable Workforce Management to Support Workmate's Business Growth", 
    description: "Aimed to enhance the user experience for workers dealing with overlapping shifts, ensuring accurate timekeeping and shift allocation.",
    image: "/placeholder.svg?height=400&width=600",
    slug: "workmate",
    date: "2018-08",
    platform: "Mobile App",
    industry: "Workforce Management",
    businessModel: "B2B",
    categories: ["B2B", "Mobile App", "Workforce Management"],
    featured: true,
    coverType: 'video',
    coverMedia: '/works/workmate/cover.mp4'
  },
]

export function getFeaturedWorks(count: number = 3) {
  return works
    .filter(work => work.featured)
    .slice(0, count)
}

export function getSortedWorks() {
  return [...works].sort((a, b) => 
    new Date(b.date).getTime() - new Date(a.date).getTime()
  )
} 