'use client'

import Image from "next/image"
import Link from "next/link"
import { useState } from "react"

import { Button } from "@/components/ui/button"
import { Badge } from "@/components/ui/badge"
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs"
import { getFeaturedWorks, getUsedCategories } from "@/data/works"
import { companies } from "@/data/companies"
import { HoverBorderGradient } from "@/components/ui/hover-border-gradient"
import { Marquee } from "@/components/marquee"
import { VideoCover } from "@/components/ui/video-cover"
import { ArrowUpRightIcon } from "@/components/icons/arrow-up-right"

export default function HomePage() {
  const featuredWorks = getFeaturedWorks(3)
  const usedCategories = getUsedCategories(featuredWorks)
  const [activeCategory, setActiveCategory] = useState("All")

  const filteredWorks = featuredWorks.filter(work =>
    activeCategory === "All" ? true : work.categories.includes(activeCategory)
  )

  return (
    <div className="min-h-screen bg-[#FBFBFB]">
      <div className="py-20">
        {/* Hero Section */}
        <div className="px-8 py-12">
          <div className="text-center mb-24">
            <p className="text-xl text-muted-foreground max-w-lg mx-auto">
              Digital Product Designer
            </p>
            <h1 className="text-4xl font-normal tracking-tight sm:text-7xl lg:text-8xl my-6">
              Arianti Silvia
            </h1>
            <p className="text-lg text-muted-foreground max-w-lg mx-auto">
              Jakarta, Indonesia
            </p>
          </div>

          <Tabs
            defaultValue="All"
            className="w-full justify-center mb-8"
            onValueChange={setActiveCategory}
          >
            <TabsList className="h-auto flex-wrap gap-2 bg-transparent p-0 justify-center w-full">
              {usedCategories.map((category) => (
                <TabsTrigger
                  key={category}
                  value={category}
                  className="rounded-full data-[state=active]:bg-primary data-[state=active]:text-primary-foreground"
                >
                  {category}
                </TabsTrigger>
              ))}
            </TabsList>
          </Tabs>
        </div>

        {/* Recent Works Grid */}
        <div className="px-8 mb-12">
          <div className="max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {filteredWorks.map((work) => (
              <HoverBorderGradient 
                key={work.slug}
                as={Link}
                href={`/works/${work.slug}`}
                className="group border-transparent"
              >
                <div className="relative aspect-[4/3] w-full">
                  {work.coverType === 'video' ? (
                    <VideoCover src={work.coverMedia} />
                  ) : (
                    <Image
                      src={work.coverMedia}
                      alt={work.title}
                      fill
                      className="object-cover rounded-t-lg"
                    />
                  )}
                </div>
                <div className="p-6 space-y-4">
                  <div className="space-y-4">
                    <div className="flex flex-wrap gap-2">
                      {work.categories.map((category) => (
                        <Badge key={category} variant="secondary">
                          {category}
                        </Badge>
                      ))}
                    </div>
                    <div className="space-y-2">
                      <h3 className="text-2xl font-medium group-hover:text-primary transition-colors">
                        {work.title}
                      </h3>
                      <p className="text-lg text-gray-600 line-clamp-2">
                        {work.subtitle}
                      </p>
                    </div>
                  </div>
                </div>
              </HoverBorderGradient>
            ))}
          </div>
        </div>

        {/* View All Works Button */}
        <div className="px-8 text-center mb-24">
          <Button variant="outline" asChild className="mx-auto">
            <Link href="/works" className=
            // "gap-2"
            "inline-flex items-center px-4 py-2 border border-gray-200 text-sm font-medium rounded-lg text-gray-900 hover:bg-gray-100 hover:text-gray-900 transition-colors"
            >
              View All Works
              <ArrowUpRightIcon />
            </Link>
          </Button>
        </div>

        {/* Companies Section */}
        <section className="pt-16">
          <div className="px-8 pt-16 text-center mb-12">
            <h2 className="text-base text-gray-600 mb-4">Previously worked with</h2>
          </div>
          <div className="pb-24">
            <Marquee>
              {companies.map((company) => (
                <div 
                  key={company.name}
                  className="w-[180px] h-12 relative hover:scale-105 transition-transform flex-shrink-0 mx-6"
                >
                  {company.url ? (
                    <Link 
                      href={company.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="block w-full h-full"
                    >
                      <Image
                        src={company.logo}
                        alt={company.name}
                        fill
                        className="object-contain"
                      />
                    </Link>
                  ) : (
                    <Image
                      src={company.logo}
                      alt={company.name}
                      fill
                      className="object-contain"
                    />
                  )}
                </div>
              ))}
            </Marquee>
          </div>
        </section>
      </div>
    </div>
  )
}